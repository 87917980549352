<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <!-- Toolbar Section -->
          <v-toolbar class="mb-1" flat>
            <v-toolbar-title><v-icon left>mdi-briefcase-variant-outline</v-icon>Explore Cases</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              clearable
              flat
              solo
              dense
              rounded
              outlined
              class="d-none d-sm-flex"
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Search"
              :class="{ 'v-input--dense': !$vuetify.breakpoint.mdAndUp }"
            ></v-text-field>
          </v-toolbar>
          
          <v-card-title class="d-flex d-sm-none align-center flex-wrap">
            <v-text-field
                v-model="search"
                clearable
                flat
                solo
                outlined
                dense
                rounded
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
            ></v-text-field>
          </v-card-title>
  
          <!-- Data Iterator for Cases -->
          <v-data-iterator
            :items="paginatedCases"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            no-data-text=""
            no-results-text=""
            hide-default-footer
          >
            <template v-slot:default="{ items, isLoading }">
              <v-row>
                <template v-if="isLoading">
                  <v-col
                    cols="12"
                    md="6"
                    v-for="n in itemsPerPage"
                    :key="n"
                  >
                    <v-skeleton-loader type="card" class="mb-4"></v-skeleton-loader>
                  </v-col>
                </template>
  
                <template v-else>
                  <v-col
                    v-for="caseItem in items"
                    :key="caseItem.id"
                    cols="12"
                    md="6"
                  >
                    <v-card style="min-height: 100%;" class="justify-center card-height" elevation="3">
                      <v-img
                        v-if="caseImage"
                        alt="Protected"
                        :src="caseImage"
                        contain
                        cover
                        no-repeat
                        position="right"
                        :style="{
                          opacity: 0.5,
                          position: 'absolute',
                          top: '10%',
                          right: '2%',
                          bottom: '10%',
                          zIndex: 0,
                        }"
                      ></v-img>
                      <v-chip
                        label
                        small
                        color="secondary"
                        class="text-overline ml-2 my-2"
                      >
                        {{ caseItem.caseId}}
                      </v-chip>
                      <v-list-item three-line>
                        <v-list-item-content>
                          <v-list-item-title 
                            class="text-h5 mb-1"
                            style="white-space: normal; 
                            word-break: break-word;"
                          >
                            {{ caseItem.title }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Author: {{ caseItem.authorName }}</v-list-item-subtitle>
                          <v-list-item-subtitle>Publisher: {{ caseItem.publisherName }}</v-list-item-subtitle>
                          <v-list-item-subtitle v-if="caseItem.editorName">Editor: {{ caseItem.editorName }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
  
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="mr-3"
                              small
                              color="primary"
                              elevation="7"
                              raised
                              rounded
                              v-bind="attrs"
                              v-on="on"
                              :to="{ name: 'case', params: { id: caseItem.id } }"
                            >
                              <v-icon small>mdi-book-open-page-variant-outline</v-icon> View Case
                            </v-btn>
                          </template>
                          <span>Open</span>
                        </v-tooltip>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </template>
              </v-row>
            </template>
  
            <!-- No Results Template -->
            <template v-slot:no-results>
              <v-empty-state
                icon="mdi-alert-circle-outline"
                title="No matching cases found"
                description="Try a different search term."
              />
            </template>
  
            <!-- No Data Template -->
            <template v-slot:no-data>
              <v-empty-state
                icon="mdi-alert-circle-outline"
                title="No case has been published"
                description="Your cases collection is currently empty."
              />
            </template>
  
            <!-- Footer with Pagination -->
            <template v-slot:footer>
              <v-row class="mt-2" align="center" justify="center">
                <v-col cols="12">
                  <v-row align="center" justify="center" class="floating-pagination">
                    <v-pagination
                      v-model="page"
                      :length="numberOfPages"
                      :total-visible="5"
                      circle
                      color="primary"
                    ></v-pagination>
                  </v-row>
                </v-col>
              </v-row>
            </template>
          </v-data-iterator>
          <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  import { mapState, mapGetters } from "vuex";
  import useLocalStorage from '@/composables/useLocalStorage';
  import VEmptyState from '@/components/VEmptyState.vue';
  
  export default {
    data() {
      return {
        // snackbar data
        snackbar: false,
        response: "",
        snackColor: "black",
        search: "",
        page: 1,
        itemsPerPage: 10,
        itemsPerPageArray: [10, 20, 30],
        caseImage: null,
        selectedCategory: '',
      };
    },
    watch: {
        search() {
            this.page = 1;
        },
        selectedCategory() {
            this.page = 1;
        },
    },
    components: {
      VEmptyState,
    },
    computed: {
      ...mapState(["currentUser", "userProfile", "cases", "settings"]),
      ...mapGetters(["darkTheme"]),
    filteredCases() {
        // Filter cases based on the search term
        return this.cases.filter((caseItem) => {
        const title = caseItem.title ? caseItem.title.toLowerCase() : '';
        const caseId = caseItem.caseId ? caseItem.caseId.toLowerCase() : '';
        const search = this.search ? this.search.toLowerCase() : '';
        const hasCategory = this.selectedCategory
            ? caseItem.categories.some(category => category.path[0].text === this.selectedCategory)
            : true;

        return (title.includes(search) || caseId.includes(search)) && hasCategory;
        });
    },
    paginatedCases() {
        const start = (this.page - 1) * this.itemsPerPage;
        const end = this.page * this.itemsPerPage;
        return this.filteredCases.slice(start, end);
    },
    numberOfPages() {
        return Math.ceil(this.filteredCases.length / this.itemsPerPage);
    },
    },
    methods: {
      printOut(item) {
        console.log(item);
      },
      applyFilter() {
        if (this.selectedCategory) {
            // Apply filtering logic based on selectedCategory
            // For example, you could filter the cases displayed on this page
            //console.log("All Cases:", this.cases);
        }
      },
    },
    created() {
      this.$store.dispatch("streamCaseLists");
      if (this.settings.length == 0) {
        this.$store.dispatch("fetchSettings");
      }
      const caseImageStorage = useLocalStorage(null, "caseImage");
      this.caseImage = caseImageStorage.get();
      this.selectedCategory = this.$route.query.category || '';
      this.applyFilter();

    },
    beforeDestroy() {
      this.$store.dispatch("detachCaseList");
    },
  };
  </script>
  
  <style>
  .btn-primary {
    margin-right: 12px;
  }
  .fill-height {
    height: 100%;
  }
  .d-flex {
    display: flex;
  }
  .justify-center {
    justify-content: center;
  }
  .align-center {
    align-items: center;
  }
  
  .no-cases-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
  }

.floating-pagination {
  position: fixed;
  bottom: 2.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
}
</style>